





























































































































































































































































































































.couraewareBox {
  display: flex;
  flex-wrap: wrap;
  .form-cell {
    width: 50%;
  }
}
.imgbox {
  .el-form-item__content {
    width: 256px !important;
    height: 156px;
  }
}
.el-textarea {
  .el-textarea__inner {
    min-height: 100px;
    resize: none;
  }
}
