.el-textarea .el-textarea__inner {
  height: 10rem !important;
}
.Basicinformation {
  display: flex;
  flex-wrap: wrap;
}
.Basicinformation > div {
  width: 50%;
  padding: 10px 0;
}
.Basicinformation .information {
  display: flex;
  flex-wrap: wrap;
}
.Basicinformation .information .information-item {
  width: 50%;
}
.lessonNum {
  display: flex;
  padding: 1rem;
}
.lessonNum span {
  padding: 0 1rem;
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}
.lessNumcount span {
  width: 10rem;
}
.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-tree-node .el-form-item {
  margin-bottom: 0px;
}
